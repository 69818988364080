<template>
  <voucher-code-editor 
    :applicationId="applicationId" 
    :voucherRuleId="voucherRuleId"
    :voucherCodeId="voucherCodeId"
    :readOnly="true"
  />
</template>

<script>
import VoucherCodeEditor from './VoucherCodeEditor.vue'

export default {
  components: {
    VoucherCodeEditor
  },
  props: {
    applicationId: { required: true},
    voucherRuleId: { required: true },
    voucherCodeId: { required: false, default: 0 }
  }
}
</script>